<template>
  <div>
    <page-title>Create Invoice</page-title>
    <page-subtitle>Create a new invoice here</page-subtitle>
    <div class="mt-5">
      <div class="bg-white p-5">
        <section-subtitle> Enter Invoice details </section-subtitle>
        <div class="flex flex-col">
          <form class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Invoice Title</label
              >
              <t-input
                class="focus:outline-none"
                type="text"
                v-model="data.title"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Client's Name</label
              >
              <t-rich-select
                :options="clients"
                placeholder="Select a client"
                value-attribute="_id"
                text-attribute="name"
                required
                v-model="data.clientId"
              >
              </t-rich-select>
              <!-- <v-select
                class="py-1"
                :options="clients"
                label="name"
                v-model="data.clientId"
                :reduce="(client) => client._id"
              ></v-select> -->
            </div>
            <div class="form-control hidden">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Status</label
              >
              <t-input
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="grid grid-cols-2 gap-2">
              <div class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Amount</label
                >
                <t-input
                  class="focus:outline-none"
                  type="number"
                  v-model="data.amount"
                  aria-autocomplete="no"
                ></t-input>
              </div>
              <div hidden class="form-control">
                <label
                  class="text-xs font-semibold text-gray-800 mb-2 block"
                  for="name"
                  >Date</label
                >
                <t-input class="focus:outline-none" type="date"></t-input>
              </div>
            </div>
            <div hidden class="form">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Purpose</label
              >
              <t-input
                class="focus:outline-none"
                type="text"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div hidden class="form">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Description</label
              >
              <t-textarea
                rows="5"
                class="focus:outline-none"
                type="type"
                aria-autocomplete="no"
              ></t-textarea>
            </div>
          </form>
          <div class="flex space-x-5 items-center justify-end mt-5">
            <t-button
              @click="$router.go(-1)"
              class="border w-28 border-accent px-3 py-1 rounded text-accent"
              variant="error"
            >
              Cancel
            </t-button>
            <t-button
              @click="createInvoice"
              class="w-28 flex items-center justify-center"
            >
              <span v-if="!loading">Save</span> <spinner v-else></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import SectionSubtitle from '../../components/Typography/SectionSubtitle.vue'
import Spinner from '../../components/Utils/Spinner.vue'
import invoiceApi from '../../api/invoice'
import clientApi from '../../api/client'

export default {
  name: 'CreateInvoice',
  components: { PageTitle, PageSubtitle, SectionSubtitle, Spinner },
  data: () => ({
    loading: false,
    clients: [],
    data: {
      title: '',
      clientId: '',
      amount: ''
    }
  }),
  created() {
    this.getClients()
  },
  methods: {
    async getClients() {
      const res = await clientApi.client().getAll({ offset: 0 }, false)
      this.clients = res.data.data.map(client => ({
        ...client,
        name: client.firstName + ' ' + client.lastName
      }))
    },
    async createInvoice() {
      this.loading = true
      const res = await invoiceApi.invoice().create({
        title: this.data.title,
        clientId: this.data.clientId,
        amount: this.data.amount
      })

      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.loading = false
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.data.clientId = ''
      this.data.title = ''
      this.data.amount = ''
      this.loading = false
      this.$router.push({ name: 'Payment' })
    }
  }
}
</script>
