<template>
  <div>
    <t-modal v-model="editPayment">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Edit Payment</h4>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
          <div class="form-control">
            <label
              class="text-xs font-semibold text-gray-800 mb-2 block"
              for="name"
              >Title</label
            >
            <t-input
              class="focus:outline-none"
              type="text"
              v-model="selectedPayment.title"
            ></t-input>
          </div>
          <!-- <div class="form-control">
            <label
              class="text-xs font-semibold text-gray-800 mb-2 block"
              for="name"
              >Paid Date</label
            >
            <t-input
              class="focus:outline-none"
              type="date"
              v-model="selectedPayment.paidAt"
              aria-autocomplete="no"
            ></t-input>
          </div> -->
          <div class="form-control">
            <label
              class="text-xs font-semibold text-gray-800 mb-2 block"
              for="name"
              >Amount</label
            >
            <t-input
              class="focus:outline-none"
              type="number"
              v-model="selectedPayment.amount"
              aria-autocomplete="no"
            ></t-input>
          </div>
        </div>
        <div class="mt-6 space-x-4 flex items-end justify-end">
          <t-button
            @click="deleteAPayment"
            class="border w-28 border-accent px-3 py-1 rounded text-accent flex items-center justify-center"
            variant="error"
          >
            <span v-if="!deletingPayment"> Delete </span>
            <spinner color="text-accent" v-else></spinner>
          </t-button>

          <t-button
            @click="editAPayment"
            class="w-28 flex items-center justify-center"
          >
            <span v-if="!editingPayment">Update</span>
            <spinner v-else></spinner>
          </t-button>
        </div>
      </div>
    </t-modal>
    <t-modal v-model="addPayment">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">
          Add Payment to Invoice
        </h4>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
          <!-- <div class="form-control">
            <label
              class="text-xs font-semibold text-gray-800 mb-2 block"
              for="name"
              >Title</label
            >
            <t-input
              class="focus:outline-none"
              type="text"
              v-model="newPayment.title"
            ></t-input>
          </div> -->
          <div class="form-control">
            <label
              class="text-xs font-semibold text-gray-800 mb-2 block"
              for="name"
              >Paid Date</label
            >
            <t-input
              class="focus:outline-none"
              type="date"
              v-model="newPayment.paidAt"
              aria-autocomplete="no"
            ></t-input>
          </div>
          <div class="form-control">
            <label
              class="text-xs font-semibold text-gray-800 mb-2 block"
              for="name"
              >Amount</label
            >
            <t-input
              class="focus:outline-none"
              type="number"
              v-model="newPayment.amount"
              aria-autocomplete="no"
            ></t-input>
          </div>
        </div>
        <div class="mt-6 flex items-end justify-end">
          <t-button
            @click="createPayment"
            class="w-28 flex items-center justify-center"
          >
            <span v-if="!addingPayment">Save</span> <spinner v-else></spinner>
          </t-button>
        </div>
      </div>
    </t-modal>
    <page-title>Payment</page-title>
    <page-subtitle>Here's the list of Payment for this Invoice</page-subtitle>
    <div class="mt-5">
      <div class="md:flex rounded md:space-x-2 items-center justify-between">
        <div
          class="search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"
        >
          <img
            class="w-5 h-5"
            src="../../assets/svgs/search.svg"
            alt="search"
          />
          <input
            v-model="search"
            class="border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0"
            type="text"
            placeholder="Search by name, email..."
          />
        </div>
        <t-button
          @click="addPayment = true"
          class="text-sm block w-full mt-2 md:mt-0 md:w-44 py-2"
          >Add Payment</t-button
        >
      </div>
      <div class="bg-white grid p-5 mt-10 rounded">
        <div class="mb-5 bg-white">
          <!-- <div
            class="tabs flex md:flex-row flex-col md:space-x-3 text-xs md:text-sm font-semibold border-b border-gray-200"
          >
            <span
              class="py-3 px-5 cursor-pointer bg-primary text-white rounded-tl"
              >All Status</span
            >
            <span class="py-3 px-5 cursor-pointer">Draft</span>
            <span class="py-3 px-5 cursor-pointer">Pending</span>
            <span class="py-3 px-5 cursor-pointer">Cancelled</span>
          </div> -->
          <div class="payment-list grid grid-cols-1 mt-5">
            <div class="flex flex-col w-full">
              <div class="overflow-x-scroll">
                <table class="table-auto min-h-full min-w-full">
                  <thead
                    class="bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"
                  >
                    <tr>
                      <th class="py-3 px-3">Client's Name</th>
                      <!-- <th class="py-3 px-3">Title</th> -->
                      <th class="py-3 px-3">Paid On</th>
                      <th class="py-3 px-3">Amount</th>
                      <th class="py-3 px-3">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="payment in dataList"
                      :key="payment._id"
                      @click="selectPayment(payment)"
                      class="text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded"
                    >
                      <td class="px-3 py-4">
                        {{ payment.client.firstName }}
                        {{ payment.client.lastName }}
                      </td>
                      <!-- <td class="px-3 py-4">{{ payment.title }}</td> -->
                      <td class="px-3 py-4">
                        {{ new Date(payment.paidAt).toDateString() }}
                      </td>
                      <td class="px-3 py-4">{{ payment.amount }}</td>
                      <td class="px-3 py-4 capitalize">{{ payment.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import invoiceApi from '../../api/invoice'
import Spinner from '../../components/Utils/Spinner'

export default {
  name: 'PaymentList',
  components: { PageTitle, PageSubtitle, Spinner },
  data: () => ({
    search: '',
    invoices: [],
    invoiceId: '',
    addPayment: false,
    editPayment: false,
    editingPayment: false,
    addingPayment: false,
    deletingPayment: false,
    payments: [],
    newPayment: {
      title: '',
      paidAt: '',
      amount: ''
    },
    selectedPayment: {}
  }),
  created() {
    this.invoiceId = this.$route.params.invoiceId
    this.getPayments()
  },
  computed: {
    dataList() {
      return this.payments.filter(c =>
        this.search
          .toLowerCase()
          .toString()
          .split(' ')
          .every(v => {
            const data = new Date(c.paidAt).toDateString() + ' ' + c.amount
            return data.toLowerCase().includes(v)
          })
      )
    }
  },
  methods: {
    selectPayment(payment) {
      this.selectedPayment = payment
      this.editPayment = true
    },
    async getPayments() {
      const res = await invoiceApi
        .invoice()
        .getPayments({ invoiceId: this.invoiceId })
      this.payments = res.data.data
    },
    async deleteAPayment() {
      this.deletingPayment = true
      const res = await invoiceApi
        .invoice()
        .deletePayment({ paymentId: this.selectedPayment._id })
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.deletingPayment = false
        return
      }
      this.getPayments()

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.editPayment = false
      this.deletingPayment = false
    },
    async editAPayment() {
      this.editingPayment = true
      const res = await invoiceApi.invoice().editPayment(
        { paymentId: this.selectedPayment._id },
        {
          amount: this.selectedPayment.amount,
          title: this.selectedPayment.title
        }
      )
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.editingPayment = false
        return
      }
      this.getPayments()

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.editPayment = false
      this.editingPayment = false
    },
    async createPayment() {
      this.addingPayment = true
      const res = await invoiceApi.invoice().createPayment(
        { invoiceId: this.invoiceId },
        {
          amount: this.newPayment.amount,
          title: this.newPayment.title,
          paidAt: this.newPayment.paidAt
        }
      )
      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.addingPayment = false
        return
      }

      this.newPayment = {
        title: '',
        paidAt: '',
        amount: ''
      }

      this.getPayments()

      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.addPayment = false
      this.addingPayment = false
    }
  }
}
</script>

<style></style>
